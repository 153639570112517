<template>
  <div class="appointment">
    <div class="top">
      <div>预约人数：90人/100人</div>
      <div>当前浴室人数：50人</div>
      <div>浴室位置占用情况：50%</div>
      <div>异常人员滞留人数：<span style="color: red;">0</span></div>
    </div>
    <div class="search">
      <a-form layout="inline">
        <a-form-item label="姓名">
          <a-input v-model="form.name" placeholder="请选择"></a-input>
        </a-form-item>
        <a-form-item label="学号">
          <a-input v-model="form.number" placeholder="请选择"></a-input>
        </a-form-item>
        <a-form-item label="日期">
          <a-range-picker v-model="timeRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD HH:mm:ss"/>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" style="margin-right: 10px;" @click="getData">查询</a-button>
          <a-button @click="resetData">重置</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="main-appoint">
      <div class="appoint-title">门禁进出情况</div>
      <div class="tableInfo"> 
        <a-table :columns="columns" :data-source="dataList"  :pagination="ipagination" rowKey="id" :loading="loading">
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { searchAppoint } from "../../api/appointment"
const columns = [
  {
    title: '序号',
    key: 'id',
    customRender:(text,scoped,index) => {
      return index + 1
    }
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '学号',
    dataIndex: 'number',
    key: 'number'
  },
  {
    title: '进入时间',
    dataIndex: 'inTime',
    key: 'inTime'
  },
  {
    title: '出去时间',
    dataIndex: 'outTime',
    key: 'outTime'
  }
]
export default {
    data() {
        return {
          columns,
          dataList:[],
          ipagination: {
            current: 1,
            pageSize: 10,
            showTotal: (total) => {
              return '共' + total + '条'
            },
            total: 0,
            size: 'default',
            onChange: (page, pageSize) => this.onPageChange(page, pageSize),
          },
          loading:true,
          form:{
            name:undefined,
            number:undefined
          },
          timeRange:[]
        }
    },
    mounted(){
      this.getData()
    },
    methods:{
      resetData(){
        this.timeRange = []
        this.ipagination.current = 1
        this.form = {
          name:undefined,
          number:undefined
        }
        this.getData()
      },
      getData(){
        let startTime = undefined
          let endTime = undefined
          if(this.timeRange.length > 0){
            [startTime,endTime] = this.timeRange
          }
        const { current, pageSize } = this.ipagination
        const { name,number } = this.form 
        this.loading = true
        searchAppoint({pageNum:current, pageSize,name,number,startTime,endTime}).then(res => {
          if(res.code == 200){
            this.ipagination.total = parseInt(res.message)
            this.dataList = res.data
            this.loading = false
          }else{
            this.$message.error(res.message)
          }
        })
      },
      onPageChange(page){
        this.ipagination.current = page
        this.getData()
      }
    }
}
</script>

<style lang="scss" scoped>
.appointment{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .top{
    height: 60px;
    display: flex;
    justify-content: space-between;
    gap: 1%;
    width: 100%;
    div{
      background: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .search{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #fff;
    width: 100%;
  }
  .main-appoint{
    flex: 1;
    background: #fff;
    width: 100%;
    padding: 10px 20px;
    overflow-y: auto;
    .appoint-title{
      width: 100%;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .tableInfo{
      width: 100%;
      overflow-y: auto;
      max-height: calc(100% - 90px);
    }
  }
}
</style>