import axios from "axios";
const baseURL = process.env.VUE_APP_API_BASE_URL;
const service = axios.create({
    baseURL: baseURL,
    timeout: 5000,
})
service.interceptors.response.use(res => {
    return res.data
},(error) => {
    return Promise.reject(error)
})



export { service }