const menuList = [
    {
        label:'预约配置',
        key:'reservation',
        type:'bank'
    },
    {
        label:'浴室使用查看',
        key:'appointment',
        type:'layout'
    },
    {
        label:'临时洗浴码生成',
        key:'code-create',
        type:'schedule'
    },
    {
        label:'预约查看',
        key:'appointment-view',
        type:'folder'
    }
]

const bathList = [{
    key:'bath1',
    name:"校区1浴室",
    value:1,
    label:"校区1浴室"
},{
    key:'bath2',
    name:"校区2浴室",
    value:2,
    label:"校区2浴室"
},{
    key:'bath3',
    name:"校区3浴室",
    value:3,
    label:"校区3浴室"
},{
    key:'bath4',
    name:"校区4浴室",
    value:4,
    label:"校区4浴室"
}]

export { menuList, bathList }