<template>
  <div class="reservation">
    <a-tabs v-model="currentTab" @change="callback">
      <a-tab-pane v-for="item in bathList" :key="item.value" :tab="item.name">
      </a-tab-pane>
    </a-tabs>
    <div class="position-operate">
      <a-button type="primary" style="margin-right: 10px;" @click="createItem">新建</a-button>
      <a-popconfirm
        title="是否确认全部开启?"
        ok-text="确定"
        cancel-text="取消"
        @confirm="openOrcloseAll(1)"
      >
        <a-button type="primary" style="margin-right: 10px;">全部开启</a-button>
      </a-popconfirm>
      <a-popconfirm
        title="是否确认全部关闭?"
        ok-text="确定"
        cancel-text="取消"
        @confirm="openOrcloseAll(0)"
      >
        <a-button>全部关闭</a-button>
      </a-popconfirm>
    </div>
    <div>
      <a-table :columns="columns" :data-source="dataList" :pagination="ipagination" rowKey="id" :loading="loading">
        <div slot="action" slot-scope="text,scoped">
          <a-popconfirm
            title="是否确认删除?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deleteItem(scoped.id)"
          >
            <a-button type="link" style="color:#F15757;">删除</a-button>
          </a-popconfirm>
          <a-button type="link" style="color: #999999;" @click="editItem(scoped)">修改</a-button>
          <a-popconfirm
            v-if="!scoped.status"
            title="是否确认开启?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="openOrclose(scoped.id,1)"
          >
            <a-button type="link" style="color:#2CA766;">开启</a-button>
          </a-popconfirm>
          <a-popconfirm
            v-else
            title="是否确认关闭?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="openOrclose(scoped.id,0)"
          >
            <a-button type="link" style="color:#F15757;">关闭</a-button>
          </a-popconfirm>
        </div>
      </a-table>
    </div>
    <a-modal
      v-if="flagEditAdd"
      :title="flagEditAdd === 1 ? '新增' : '修改'"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form-model :label-col="labelCol" :model="form" ref="formRef" :wrapper-col="wrapperCol" :rules="rules" >
        <a-form-model-item
          label="浴室名称"
          prop="bathId"
        >
          <a-select v-model="form.bathId" :options="bathList" placeholder="请选择"/>
        </a-form-model-item>
        <a-form-model-item
          label="开始时间"
          prop="startTime"
        >
          <a-time-picker v-model="form.startTime" valueFormat="HH:mm" format="HH:mm" placeholder="请选择"/>
        </a-form-model-item>
        <a-form-model-item
          label="结束时间"
          prop="endTime"
        >
          <a-time-picker v-model="form.endTime" valueFormat="HH:mm" format="HH:mm" placeholder="请选择"/>
        </a-form-model-item>
        <a-form-model-item
          label="最大预约人数"
          prop="maxNum"
        >
          <a-input-number v-model="form.maxNum" :min="1" :max="10000" placeholder="请输入" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { bathList } from "../../utils/dictionary.js"
import { searchListReservate, addReservate, editReservate,deleteReservate, openOrcloseReservate } from "../../api/reservation"
const columns = [
  {
    title: '序号',
    key: 'id',
    customRender:(text,scoped,index) => {
      return index + 1
    }
  },
  {
    title: '开始',
    dataIndex: 'startTime',
    key: 'startTime'
  },
  {
    title: '结束',
    dataIndex: 'endTime',
    key: 'endTime'
  },
  {
    title: '最大预约数',
    dataIndex: 'maxNum',
    key: 'maxNum'
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  }
]
export default {
    data() {
        return {
          bathList,
          columns,
          dataList:[],
          visible:false,
          confirmLoading:false,
          labelCol: {
            span:6
          },
          wrapperCol: {
            span:18
          },
          form:{
            bathId:undefined,
            startTime:undefined,
            endTime:undefined,
            maxNum:undefined
          },
          /* 分页参数 */
          ipagination: {
            current: 1,
            pageSize: 10,
            showTotal: (total) => {
              return '共' + total + '条'
            },
            total: 0,
            size: 'default',
            onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
          },
          loading:true,
          currentTab:1,
          rules: {
            bathId: [
              { required: true, message: '请选择浴室', trigger: 'change' }
            ],
            startTime: [
              { required: true, message: '请选择', trigger: 'change' }
            ],
            endTime: [
              { required: true, message: '请选择', trigger: 'change' }
            ],
            maxNum: [
              { required: true, message: '请输入', trigger: 'blur' }
            ]
          },
          flagEditAdd:0
        }
    },
    mounted(){
      this.getData()
    },
    methods:{
      createItem(){
        this.flagEditAdd = 1
        this.visible = true
      },
      openOrcloseAll(status){
        openOrcloseReservate({status}).then(res => {
          if(res.code === 200){
            this.$message.success(status === 1 ? '全部开启成功' : '全部关闭成功')
            this.getData()
          }else{
            this.$message.error(res.message)
          }
        })
      },
      deleteItem(id){
        deleteReservate({id}).then(res => {
          if(res.code === 200){
            this.ipagination.current = 1
            this.$message.success('删除成功')
            this.getData()
          }else{
            this.$message.error(res.message)
          }
        })
      },
      openOrclose(id,status){
        editReservate({id,status}).then(res => {
          if(res.code === 200){
            this.$message.success(status === 1 ? '开启成功' : '关闭成功')
            this.getData()
          }else{
            this.$message.error(res.message)
          }
        })
      },
      editItem(scoped){
        this.flagEditAdd = 2
        this.form = {
          ...scoped
        }
        this.visible = true
      },
      callback(key) {
        this.ipagination.current = 1
        this.getData()
      },
      handleOk(){
        this.$refs.formRef.validate((valid) => {
          if(valid){
            if(this.flagEditAdd == 1){
              addReservate(this.form).then(res => {
                if(res.code === 200){
                  this.ipagination.current = 1
                  this.$message.success('添加成功')
                  this.getData()
                  this.resetData()
                }else{
                  this.$message.error(res.message)
                }
              })
            }else if(this.flagEditAdd == 2){
              editReservate(this.form).then(res => {
                if(res.code === 200){
                  this.$message.success('编辑成功')
                  this.getData()
                  this.resetData()
                }else{
                  this.$message.error(res.message)
                }
              })
            }else{

            }
          }
        })
        
      },
      //清空数据
      resetData(){
        this.form = {
          bathId:undefined,
          startTime:undefined,
          endTime:undefined,
          maxNum:undefined
        }
        this.visible = false;
        this.flagEditAdd = 0
      },
      handleCancel(){
        this.resetData()
      },
      getData(){
        const { current, pageSize } = this.ipagination
        const { currentTab } = this
        this.loading = true
        searchListReservate({pageNum:current, pageSize,bathId:currentTab}).then(res => {
          if(res.code == 200){
            this.ipagination.total = parseInt(res.message)
            this.dataList = res.data
            this.loading = false
          }else{
            this.$message.error(res.message)
          }
        })
      },
      onPageChange(page){
        this.ipagination.current = page
        this.getData()
      }
    }
}
</script>

<style lang="scss" scoped>
.reservation{
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 10px 20px;
  overflow-y: auto;
  .position-operate{
    position: absolute;
    right: 0;
    top: 10px;
    right: 20px;
  }
}
</style>