import { service } from "../service.js"

//预约配置

//查询
export function searchAppoint(data={}){
    return service({
        url:'/reservationMobile/getMenJin',
        method:'get',
        params:data
    })
}





