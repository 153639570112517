<template>
    <div class="home-container">
      <div class="menu-list">
        <div class="banner">
          <img src="../../assets//wgy-logo.png" alt="" style="width: 50px;">
          <div>
            <span class="first">天津外国语大学</span>
            <span class="two">浴室预约管理系统演示</span>
          </div>
        </div>
        <MenuList></MenuList>
      </div>
      <div class="main">
        <div class="main-top">

        </div>
        <div class="main-content">
            <router-view></router-view>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MenuList from "../../components/Home/MenuList.vue"
  export default {
    components:{
        MenuList
    },
    data() {
      return {
        
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .home-container{
    display: flex;
    width: 100%;
    height: 100%;
    .menu-list{
        width: 300px;
        height: 100%;
        background: #fff;
        .banner{
          display: flex;
          align-items: center;
          height: 70px;
          width:100%;
          padding: 10px 20px;
          gap: 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
          }
          .first{
            font-size: 18px;
            font-weight: bold;
            color: #1DA57A;
          }
          .two{
            font-size: 18px;
            font-weight: bold;
          }
        }
    }
    .main{
        width:calc(100% - 300px);
        height: 100%;
        &-top{
            width: 100%;
            height: 70px;
        }
        &-content{
            width: 100%;
            height: calc(100% - 70px);
            padding: 17px 24px;
            background-color: #F0F2F5;
        }
    }
  }
  </style>