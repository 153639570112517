<template>
    <a-menu mode="inline" theme="light" :selectedKeys="[$route.meta.key]" style="width: 100%;height: calc(100% - 70px);" @click="openNewPage">
        <template v-for="(item) in menuList">
            <a-menu-item :key="item.key">
                <a-icon :type="item.type" />
                {{ item.label }}
            </a-menu-item>
        </template>
    </a-menu>
</template>

<script>
import { menuList } from "../../utils/dictionary.js"
export default {
    name:'MenuList',
    data() {
        return {
            menuList
        }
    },
    methods:{
        openNewPage(e){
            this.$router.push('/home/' + e.key)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>