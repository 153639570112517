<template>
    <div class="reservation">
        <div class="reservation-top">
            <a-form layout="inline">
                <a-form-item label="日期">
                    <a-range-picker v-model="timeRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD"/>
                </a-form-item>
                <a-form-item label="学号">
                    <a-input v-model="form.number" placeholder="请选择"></a-input>
                </a-form-item>
                <a-form-item label="是否进入">
                    <a-select v-model="form.isIn" :options="[{ value: '1', label: '是' },{value: '0', label: '否'}]" style="width: 100px;" placeholder="请选择"/>
                </a-form-item>
                <a-form-item label="是否洗完">
                    <a-select v-model="form.isOut" :options="[{ value: '1', label: '是' },{value: '0', label: '否'}]" style="width: 100px;" placeholder="请选择"/>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" style="margin-right: 10px;" @click="getData">查询</a-button>
                    <a-button @click="resetInfo">重置</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="reservation-main">
            <a-tabs v-model="form.bathId" @change="callback">
                <a-tab-pane v-for="item in bathList" :key="item.value" :tab="item.name">
                </a-tab-pane>
            </a-tabs>
            <div>
                <a-table :columns="columns" :data-source="dataList" :pagination="ipagination" rowKey="id" :loading="loading">
                </a-table>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { bathList } from "../../utils/dictionary.js"
  import { searchAppointView } from "../../api/appointment-view"
  const columns = [
    {
      title: '序号',
      key: 'id',
      customRender:(text,scoped,index) => {
        return index + 1
      }
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex',
      customRender:(text) => {
        return 1 == text ? '男' : '女'
      }
    },
    {
      title: '学号',
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: '预约时间',
      dataIndex: 'reservationTime',
      key: 'reservationTime'
    },
    {
      title: '进入时间',
      dataIndex: 'inTime',
      key: 'inTime',
      customRender:(text) => {
        return text || '--'
      }
    },
    {
      title: '出去时间',
      dataIndex: 'outTime',
      key: 'outTime',
      customRender:(text) => {
        return text || '--'
      }
    }
    
  ]
  export default {
      data() {
          return {
            bathList,
            columns,
            dataList:[],
            labelCol: {
              span:6
            },
            wrapperCol: {
              span:18
            },
            form:{
              bathId:1,
              isIn:undefined,
              isOut:undefined,
              number:undefined
            },
            /* 分页参数 */
            ipagination: {
              current: 1,
              pageSize: 10,
              showTotal: (total) => {
                return '共' + total + '条'
              },
              total: 0,
              size: 'default',
              onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
            },
            loading:true,
            timeRange:[]
          }
      },
      mounted(){
        this.getData()
      },
      methods:{
        callback(key) {
          this.ipagination.current = 1
          this.getData()
        },
        getData(){
          let startTime = undefined
          let endTime = undefined
          if(this.timeRange.length > 0){
            [startTime,endTime] = this.timeRange
          }
          const { current, pageSize } = this.ipagination
          this.loading = true
          searchAppointView({pageNum:current, pageSize,...this.form,startTime,endTime}).then(res => {
            if(res.code == 200){
              this.ipagination.total = parseInt(res.message)
              this.dataList = res.data
              this.loading = false
            }else{
              this.$message.error(res.message)
            }
          }).catch(error => {

          })
        },
        onPageChange(page){
          this.ipagination.current = page
          this.getData()
        },
        resetInfo(){
          this.form.isIn = undefined
          this.form.isOut = undefined
          this.form.number = undefined
          this.timeRange = []
          this.getData()
        }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .reservation{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .reservation-top{
        display: flex;
        align-items: center;
        padding: 10px 20px;
        background: #fff;
        width: 100%;
    }
    .reservation-main{
        padding: 20px;
        flex: 1;
        width: 100%;
        background: #fff;
    }
  }
  </style>