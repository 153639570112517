import { service } from "../service.js"

//预约配置

//查询
export function searchListReservate(data={}){
    return service({
        url:'/reservation/list',
        method:'get',
        params:data
    })
}

//新增
export function addReservate(data={}){
    return service({
        url:'/reservation/add',
        method:'post',
        data:data
    })
}

//编辑
export function editReservate(data={}){
    return service({
        url:'/reservation/edit',
        method:'post',
        data:data
    })
}

export function deleteReservate(data={}){
    return service({
        url:'/reservation/delete?id='+ data.id,
        method:'delete'
    })
}



//全部开启与关闭
export function openOrcloseReservate(data={}){
    return service({
        url:'/reservation/changeStatusAll',
        method:'get',
        params:data
    })
}



