import { service } from "../service.js"

//预约配置

//查询
export function searchCodeCreate(data={}){
    return service({
        url:'/temporaryCode/list',
        method:'get',
        params:data
    })
}

//新增
export function addCodeCreate(data={}){
    return service({
        url:'/temporaryCode/add',
        method:'post',
        data:data
    })
}





