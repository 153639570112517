<template>
  <a-config-provider :locale="zhCN">
    <div class="container">
    <router-view></router-view>
  </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
  name: 'App',
  data(){
    return {
      zhCN
    }
  }
}
</script>

<style lang="scss">
  .container{
    height: 100vh;
    width: 100vw;
  }
  @import "./styles//common.scss"
</style>
