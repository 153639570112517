import { service } from "../service.js"

//预约配置

//查询
export function searchAppointView(data={}){
    return service({
        url:'/reservationMobile/list',
        method:'get',
        params:data
    })
}





