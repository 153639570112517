<template>
  <div class="code-create">
    <div class="top">
      <a-form-model :model="form" ref="formRef" layout="inline">
        <a-form-model-item label="姓名">
          <a-input v-model="form.name" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="学号">
          <a-input v-model="form.number" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号">
          <a-input v-model="form.phone" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-popconfirm
            title="是否确认生成临时洗浴码?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="createCode()"
          >
            <a-button type="primary" style="margin-right: 10px;">生成临时洗浴码</a-button>
          </a-popconfirm>
          <a-button style="margin-right: 10px;" @click="resetInfo">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="main-appoint">
      <div class="appoint-title">临时洗浴码历史记录</div>
      <a-table :columns="columns" :data-source="dataList"  :pagination="ipagination" rowKey="id" :loading="loading">
        <template slot="code" slot-scope="text">
          <span style="color: #1DA57A;font-size: 18px;">{{ text }}</span>
        </template>
      </a-table>
    </div>
    <a-modal
      title="洗浴码信息"
      :visible="visible"
      :footer="null"
      @cancel="cancleModal"
    >
      <div style="text-align: center;font-size: 15px;font-weight: 600;">
        <p>姓名：{{ createInfo.name }}</p>
        <p>学号：{{ createInfo.number }}</p>
        <p>手机号：{{ createInfo.phone }}</p>
        <p>临时洗浴码：<span style="font-weight: 700;color: red;font-size: 25px;">{{ createInfo.code }}</span></p>
        <p>生成时间: {{ createInfo.createTime }}</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { searchCodeCreate, addCodeCreate } from "../../api/code-create"
const columns = [
  {
    title: '序号',
    key: 'id',
    customRender:(text,scoped,index) => {
      return index + 1
    }
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '学号',
    dataIndex: 'number',
    key: 'number'
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: '临时洗浴码',
    dataIndex: 'code',
    key: 'code',
    scopedSlots: { customRender: 'code' },
    
  },
  {
    title: '生成时间',
    dataIndex: 'createTime',
    key: 'createTime'
  }
]
export default {
    data() {
        return {
          columns,
          dataList:[],
          visible: false,
          /* 分页参数 */
          ipagination: {
            current: 1,
            pageSize: 10,
            showTotal: (total) => {
              return '共' + total + '条'
            },
            total: 0,
            size: 'default',
            onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
          },
          loading:false,
          form:{
            number:undefined,
            name:undefined,
            phone:undefined
          },
          createInfo:{
            number:undefined,
            name:undefined,
            phone:undefined,
            code:undefined,
            createTime:undefined
          }
        }
    },
    mounted(){
      this.getData()
    },
    methods:{
      resetInfo(){
        this.form = {
          number:undefined,
          name:undefined,
          phone:undefined
        }
        this.getData()
      },
      createCode(){
        const { name, number, phone } = this.form
        if(name && number && phone){
          addCodeCreate({name, number, phone}).then(res => {
            if(res.code == 200){
              this.createInfo = {
                number:undefined,
                name:undefined,
                phone:undefined,
                code:undefined,
                createTime:undefined
              }
              this.visible = true
              this.$message.success("生成成功")
              this.resetInfo()
              this.createInfo = {
                name:res.data.name,
                number:res.data.number,
                phone:res.data.phone,
                code:res.data.code,
                createTime:res.data.createTime
              }
            }else{
              this.$message.error(res.message)
            }
          })
        }else{
          this.$message.warn('请输入完整信息')
        }
      },
      cancleModal(){
        this.visible = false
      },
      getData(){
        const { current, pageSize } = this.ipagination
        this.loading = true
        searchCodeCreate({pageNum:current, pageSize}).then(res => {
          if(res.code == 200){
            this.ipagination.total = parseInt(res.message)
            this.dataList = res.data
            this.loading = false
          }else{
            this.$message.error(res.message)
          }
        })
      },
      onPageChange(page){
        this.ipagination.current = page
        this.getData()
      }
    }
}
</script>

<style lang="scss" scoped>
.code-create{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .top{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #fff;
    width: 100%;
  }
  .main-appoint{
    flex: 1;
    background: #fff;
    width: 100%;
    padding: 10px 20px 20px 20px;
    overflow-y: auto;
    .appoint-title{
      width: 100%;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}
</style>