import VueRouter from "vue-router";
import Vue from "vue";
import { Test, Home, Reservation, CodeCreate, AppointmentView, Appointment  } from "../pages";
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
Vue.use(VueRouter)



const router = new VueRouter({
    routes:[
        {
            path:"/",
            redirect: "/home"
        },
        {
            path:"/home",
            component:Home,
            redirect: "/home/reservation",
            meta:{
                key:'home'
            },
            children:[
                {
                    path:"/home/reservation",
                    component:Reservation,
                    meta:{
                        key:'reservation'
                    }
                },
                {
                    path:"/home/appointment-view",
                    component:AppointmentView,
                    meta:{
                        key:'appointment-view'
                    }
                },
                {
                    path:"/home/appointment",
                    component:Appointment,
                    meta:{
                        key:'appointment'
                    }
                },
                {
                    path:"/home/code-create",
                    component:CodeCreate,
                    meta:{
                        key:'code-create'
                    }
                }
            ]
        },
        {
            path:"/test",
            component:Test,
            meta:{
                key:'test'
            }
        }
    ]
})


export default router